import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button as MaterialButton } from '@material-ui/core';
import { isMobile, isMobileOnly, isTablet } from 'mobile-device-detect';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, FormGroup, Input } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { isIOS } from 'react-device-detect';
import Drawer from '@material-ui/core/Drawer';
import axios from 'axios';
import Hashids from 'hashids/cjs';
import Dropdown from '../../components/bs-dropdown';
import HumidorEntryCard from '../../components/HumidorEntryCardList';
import Placeholder from '../../../config/placeholder.config';
import ActionSheet from '../../components/ActionSheet';
import ModalView from '../../components/ModalView';
import MobileSearchBar from '../../components/MobileSearchBar';
import Icon from '../../components/Icon';
import VineMenu, { VineMenuDropDown } from '../../components/VineMenu';
import { config } from '../../settings';
import ShareIntent from '../../utils/shareUtils';
import HumidorEntryEditor from '../HumidorEntryEditor';
import HumidorManager from '../HumidorManager';
import ShareItem from '../../models/ShareItem';
import { toBase64, uploadToS3 } from '../../utils/imageUtils';
import './style.scss';
import MediaCropper from '../../components/cropper';
import HumidorEditor from '../HumidorEditor';
import Skeleton from '@material-ui/core/Skeleton';
import HumidorEntryCardSkeleton from '../../components/HumidorEntryCardList/skeleton';
import ErrorLogger from '../../utils/errorLogger';
import AppBar from '@material-ui/core/AppBar';
import { selectHumidorLoading } from './selectors';
import { requestHumidors as requestHumidorsAction } from './actions';
import Helmet from 'react-helmet';
import HumidorEntryCardGrid from '../../components/HumidorEntryCardGrid';
import Grid from '@material-ui/core/Grid';
import safeAreaInsets from 'safe-area-insets';
import { v4 as uuidv4 } from 'uuid';
import HumidorEntryCardCircle from '../../components/HumidorEntryCardCircle';
import { HumidorStore } from '../../stores';
import { Transducer } from '../../utils/transducer';
import Fab from '@material-ui/core/Fab';
import ModalDialog from '../../components/ModalDialog';

const hashids = new Hashids('', 12);

let addCoverPhoto;
let isFiltered = false;

const sortAlphabetically = (a, b) => {
  if (a === b) {
    return 0;
  }
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return -1;
};

const checkNull = (a, b) => {
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return null;
};

export const sortContents = (entries, sortType = 'date') => {
  console.log('Sorting:');
  console.log(sortType);
  if (!entries || !entries.length) {
    return [];
  }
  // We need to make it mutable first
  let sortFunction = (a, b) => ((b.timestamp < a.timestamp) ? -1 : ((b.timestamp > a.timestamp) ? 1 : 0));
  if (sortType === 'origin') {
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const originA = a.scan.cigar ? a.scan.cigar.origin : null;
      const originB = b.scan.cigar ? b.scan.cigar.origin : null;
      return sortAlphabetically(originA, originB);
    };
  } else if (sortType === 'wrapper') {
    console.log('Sorting by wrapper:');
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const wrapperA = a.scan.cigar ? a.scan.cigar.wrapper : null;
      const wrapperB = b.scan.cigar ? b.scan.cigar.wrapper : null;
      return sortAlphabetically(wrapperA, wrapperB);
    };
  } else if (sortType === 'name_asc') {
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const cigarA = a.scan.cigar;
      const cigarB = b.scan.cigar;
      return cigarA.full_name.localeCompare(cigarB.full_name);
    };
  } else if (sortType === 'name_desc') {
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const cigarA = a.scan.cigar;
      const cigarB = b.scan.cigar;
      return cigarB.full_name.localeCompare(cigarA.full_name);
    };
  } else if (sortType === 'price_low') {
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const hasNullPrice = checkNull(a.price, b.price);
      if (hasNullPrice) {
        return hasNullPrice;
      }
      let pricePerCigarA = 10000000.0;
      if (a.price) {
        pricePerCigarA = a.price / a.price_qty;
      }
      let pricePerCigarB = 10000000.0;
      if (b.price) {
        pricePerCigarB = b.price / b.price_qty;
      }
      return pricePerCigarA - pricePerCigarB;
    };
  } else if (sortType === 'price_high') {
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const hasNullPrice = checkNull(a.price, b.price);
      if (hasNullPrice) {
        return hasNullPrice;
      }
      let pricePerCigarA = 10000000.0;
      if (a.price) {
        pricePerCigarA = a.price / a.price_qty;
      }
      let pricePerCigarB = 10000000.0;
      if (b.price) {
        pricePerCigarB = b.price / b.price_qty;
      }
      return pricePerCigarB - pricePerCigarA;
    };
  } else if (sortType === 'strength') {
    sortFunction = (a, b) => {
      const hasNull = checkNull(a.scan.cigar, b.scan.cigar);
      if (hasNull) {
        return hasNull;
      }
      const cigarA = a.scan.cigar;
      const cigarB = b.scan.cigar;
      return cigarB.strength - cigarA.strength;
    };
  }
  // console.log(entries.sort(sortFunction));
  return entries.sort(sortFunction);
};

let imageLoader;
let preventUpdate = false;

export function HumidorComponent(props) {
  const [state, setState] = useState({
    showSortOptions: false,
    showModal: false,
    showHumidorSortSheet: false,
    showHumidorsDropDown: false,
    showHumidorManager: false,
    showHumidorEditor: false,
  });

  const allHumidors = HumidorStore.useState((s) => s.humidors);
  const [loading, setLoading] = useState(props.loading);
  const [humidors, setHumidors] = useState([]);
  const [displayType, setDisplayType] = useState(Transducer.getPreference('humidorDisplayType') || 'list');
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [selectedSort, setSelectedSort] = useState(Transducer.getPreference('humidorSelectedSort') || 'date'); // (sort && sort.length) ? sort
  const lastUsedHumidor = HumidorStore.useState((s) => s.humidors.activeHumidor);
  const [activeHumidor, setActiveHumidor] = useState({});
  const [activeHumidorImage, setActiveHumidorImage] = useState(Placeholder.humidor);
  const [contents, setContents] = useState([]); // (humidors && humidors.length) ? humidors[0].contents : [],
  const [selectedCigar, setSelectedCigar] = useState(null); // Used for the entry editor selectedCigar: null, // Used for the entry editor
  const [showHumidorEntryEditor, setShowHumidorEntryEditor] = useState(false);

  let filterSearchBar;

  const sortOptions = [{
    content: 'Date Added',
    onClick: () => {
      sort('date');
    },
  }, {
    content: 'Country of Origin',
    onClick: () => {
      sort('origin');
    },
  }, {
    content: 'Wrapper',
    onClick: () => {
      sort('wrapper');
    },
  }, {
    content: 'Strength',
    onClick: () => {
      sort('strength');
    },
  }, {
    content: 'Title - A to Z',
    onClick: () => {
      sort('name_asc');
    },
  }, {
    content: 'Title - Z to A',
    onClick: () => {
      sort('name_desc');
    },
  }, {
    content: 'Price - Low to High',
    onClick: () => {
      sort('price_low');
    },
  }, {
    content: 'Price - High to Low',
    onClick: () => {
      sort('price_high');
    },
  }];

  useEffect(() => {
    // console.log('Got humidors from props!');
    let lastSelectedHumidor = (isCurrentUser && lastUsedHumidor) || null;
    if (humidors.length) {
      console.log('Last selected humidor:');
      console.log(lastSelectedHumidor);

      if (lastSelectedHumidor) {
        if (!lastSelectedHumidor.contents) {
          lastSelectedHumidor.contents = [];
        }
        if (humidors) {
          humidors.forEach((humidor) => {
            if (humidor.id === lastSelectedHumidor.id) {
              // Make sure we're using the most recent copy - can we handle this in a Reducer or something?
              lastSelectedHumidor = humidor;
            }
          });
        }
      } else {
        lastSelectedHumidor = humidors[0];
      }
      console.log('Updating active humidor...');
      console.log(lastSelectedHumidor);
      if (!preventUpdate) {
        setActiveHumidor(lastSelectedHumidor);
      }
      setLoading(false);
    }
  }, [humidors, lastUsedHumidor]);

  // FIXME I commented this out for a reason - I assume related to adding / editing an entry but not sure why this
  //  wouldn't still be valid to have
  //  Ahh.. I think I see - I had to make the contents mutable now that it uses PullState. This means, this causes
  //  a loop that indefinitely calls sort(); - not sure on the best approach
  // INFO I went ahead and changed this to sort ahead of time rather than with an effect and it's much more smooth now
  //  Test through to make sure every aspect works as intended and then delete this block of code
  // useEffect(() => {
  //   sort(selectedSort);
  // }, [contents]);

  useEffect(() => {
    const { auth } = props;
    const { user } = auth || {};
    let userId = user ? user.id : -1;

    let currentUser = window.location.pathname === '/profile/humidors';

    if (!currentUser) {
      const path = window.location.pathname;
      console.log(path);
      const parts = path.split('/');
      console.log(parts);
      const hashId = parts[2];
      console.log(hashId);
      const currentId = hashids.decode(hashId)[0];

      if (currentId === userId) {
        currentUser = true;
      } else {
        currentUser = false;
        userId = currentId;
      }
    }
    setIsCurrentUser(currentUser);
    setCurrentUserId(userId);

    console.log('Requesting humidors:');
    console.log(userId);
    props.requestHumidors(userId);
  }, [] /* props.match, window.location.pathname */);

  // useEffect(() => {
  //   if (props.user) {
  //     const auth = props.auth || {};
  //     const user = auth.user || {};
  //     if (props.user.id === user.id) {
  //       setIsCurrentUser(true);
  //       setCurrentUserId(props.user.id);
  //     }
  //     console.log(props.user.id);
  //     props.requestHumidors(props.user.id);
  //   }
  // }, [props.user]);

  useEffect(() => {
    if (activeHumidor) {
      console.log('Active humidor:');
      console.log(activeHumidor);
      preloadHumidorImage(activeHumidor);
      // FIXME This is what was causing the issue where the humidor would get wiped when returning
      if (activeHumidor.contents/* && !isFiltered */) {
        // console.log('Updating humidor contents...');
        setContents(sortContents([...activeHumidor.contents], selectedSort));
      }
      if (isCurrentUser) {
        HumidorStore.update((s) => {
          s.humidors.activeHumidor = activeHumidor;
          const userHumidors = [...s.humidors[currentUserId]];
          for (let j = 0; j < userHumidors.length; j++) {
            if (userHumidors[j].id === activeHumidor.id) {
              userHumidors[j] = activeHumidor;
            }
          }
          s.humidors[currentUserId] = userHumidors;
        });
        Transducer.setPreference('activeHumidor', activeHumidor);
      }
    }
  }, [activeHumidor]);

  useEffect(() => {
    console.log('Contents:');
    console.log(contents);
  }, [contents]);

  useEffect(() => {
    if (currentUserId && currentUserId > 0) {
      const userHumidors = allHumidors[currentUserId];
      console.log('Got user humidors from pullstate!');
      console.log(userHumidors);
      if (userHumidors) {
        setHumidors(userHumidors);
        setLoading(false);
      }
    }
  }, [currentUserId, allHumidors]);

  useEffect(() => {
    if (isMobile) {
      if (document.getElementById('main-mobile-search-bar')) {
        document.getElementById('main-mobile-search-bar').style.display = 'none';
      }
    }
    return () => {
      if (isMobile) {
        // FIXME Sometimes this fires after the componentWillMount from another component and shows it when it shouldn't
        if (document.getElementById('main-mobile-search-bar')) {
          document.getElementById('main-mobile-search-bar').style.display = 'flex';
        }
      }
    };
  }, []);

  useEffect(() => {
    console.log('componentDidUpdate props changed');
    if (props.location) {
      const urlParams = new URLSearchParams(props.location.search);
      const addEntryAction = !!urlParams.get('action') && urlParams.get('action') === 'add';
      const cigarId = urlParams.get('itemId');
      if (addEntryAction) {
        window.dispatchEvent(new CustomEvent('hideSearch', {
          detail: { removeSearchBar: true },
        }));
        if (isMobile) {
          if (document.getElementById('main-mobile-search-bar')) {
            document.getElementById('main-mobile-search-bar').style.display = 'none';
          }
        }
        // FIXME Loading indicator
        console.log('Showing the humidor entry editor...');
        axios.get(`${config.apiEndPoint}/cigars/${cigarId}`)
          .then((response) => {
            urlParams.delete('action');
            urlParams.delete('itemId');
            props.history.replace({
              pathname: window.location.pathname,
              search: urlParams.toString(),
            });
            setSelectedCigar(response.data);
            setShowHumidorEntryEditor(true);
          });
      }
    }
  }, [props.location.search]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const share = () => {
    console.log('Sharing...');
    const { auth } = props;
    const { user } = auth;
    ShareIntent.share(new ShareItem({
      id: hashids.encode(activeHumidor.id), // Hash ID of the object
      title: activeHumidor.name,
      // text: `${user.first_name} shared their virtual humidor with you.`,
      text: 'Check out the great smokes in my virtual humidor. I use Boxpressd to keep track of which cigars I like and which I don\'t.',
      image: activeHumidor.image_url,
      path: 'humidor',
      route: `/users/${hashids.encode(user.id)}/humidors`,
    }));
  };

  const uploadMedia = (blob, extension) => {
    const uuid = uuidv4();
    const userHash = hashids.encode(props.auth.user.id);
    const humidorHash = hashids.encode(activeHumidor.id);
    const filename = `${uuid}-${userHash}-${humidorHash}.${extension}`;

    console.log(filename);
    console.log('Uploading to media server...');

    // TODO Show "uploading" animation on the image, hide after success

    // INFO We need to create a new object otherwise it won't re-render
    const humidor = { ...activeHumidor };
    toBase64(blob).then((result) => {
      humidor.image_url = result;
      preventUpdate = true; // This is to prevent a re-render of the cached humidor which overwrites the next line
      setActiveHumidor(humidor);
    });

    uploadToS3(blob, 'humidors', filename, uuid).then((res) => {
      console.log('Uploaded to media server!');
      console.log(res.data);

      const updatedHumidor = { ...humidor };
      updatedHumidor.image_url = res.data.media_url;

      HumidorStore.update((s) => {
        const localHumidors = s.humidors;
        if (localHumidors[updatedHumidor.id]) {
          localHumidors[updatedHumidor.id].image_url = res.data.media_url;
        }
      });

      // FIXME This isn't being called in staging but runs perfectly fine in dev
      axios.put(`${config.apiEndPoint}/humidors/${updatedHumidor.id}`, updatedHumidor).then((updatedResponse) => {
        console.log('Successfully updated humidor');
        console.log(updatedResponse.data);
        setActiveHumidor(updatedHumidor);
      }).catch((err) => {
        ErrorLogger.captureException(err);
        preventUpdate = false;
      });
    }).catch((err) => {
      console.log(err);
      ErrorLogger.captureException(err);
      preventUpdate = false;
    });
  };

  const preloadHumidorImage = (humidor) => {
    if (humidor) {
      const activeImage = humidor.image_url;
      if (activeImage && activeImage.indexOf('base64') !== -1) {
        setActiveHumidorImage(activeImage);
      } else {
        setActiveHumidorImage(activeImage ? activeImage.replace('/humidors/', '/humidors/200x100/') : Placeholder.humidor);
        if (activeImage) {
          if (imageLoader) {
            imageLoader.onload = null;
          }
          imageLoader = new Image();
          imageLoader.src = activeImage;
          imageLoader.onload = () => {
            setActiveHumidorImage(activeImage);
          };
        }
      }
    }
  };

  const toggleDisplayView = () => {
    if (displayType === 'list') {
      setDisplayType('grid');
      Transducer.setPreference('humidorDisplayType', 'grid');
    } else if (displayType === 'grid') {
      setDisplayType('circle');
      Transducer.setPreference('humidorDisplayType', 'circle');
    } else {
      setDisplayType('list');
      Transducer.setPreference('humidorDisplayType', 'list');
    }
  };

  const closeModal = () => {
    console.log('Closing modal...');
    window.history.back();
  };

  const toggleShowHumidorEditor = () => {
    console.log('Showing humidor editor...');
    setState({
      showHumidorEditor: !state.showHumidorEditor,
    });
  };

  const closeHumidorEditor = () => {
    setState({
      showHumidorEditor: false,
    });
  };

  const toggleHumidorSortSheet = () => {
    setState({
      showHumidorSortSheet: !state.showHumidorSortSheet,
    });
  };

  const toggleHumidorEntryEditor = () => {
    setShowHumidorEntryEditor((prevState) => !prevState);
  };

  const closeHumidorEntryEditor = () => {
    setShowHumidorEntryEditor(false);
  };

  const toggleHumidorManager = () => {
    setState({
      showHumidorManager: !state.showHumidorManager,
    });
  };

  const closeHumidorManager = () => {
    setState({
      showHumidorManager: false,
    });
  };

  const toggleHumidorsDropDown = () => {
    setState({
      showHumidorsDropDown: !state.showHumidorsDropDown,
    });
  };

  const calculateCigarCount = () => {
    let count = 0;
    contents && contents.forEach((entry) => {
      count += entry.qty;
    });
    return count;
  };

  const calculateTotalValue = () => humidorTotalValue(activeHumidor);

  const humidorTotalValue = (humidor) => {
    const { contents } = humidor || {};
    let cost = 0;
    contents && contents.forEach((entry) => {
      if (entry.price) {
        if (entry.price_type === 'Single') {
          cost += entry.price * entry.qty;
        } else {
          cost += entry.price;
        }
      }
    });
    return `$${cost.toFixed(2)}`;
  };

  const humidorTotalEntryCount = (humidor) => {
    let count = 0;
    const { contents } = humidor || {};
    contents && contents.forEach((entry) => {
      count += entry.qty;
    });
    return count;
  };

  const sort = (sortType) => {
    console.log('Sorting:');
    console.log(sortType);
    setSelectedSort(sortType);
    if (isCurrentUser) {
      // alert(`Stored sort selection: ${sortType}`);
      Transducer.setPreference('humidorSelectedSort', sortType);
    }
    setContents(sortContents(contents, sortType));
  };

  const renderNoHumidor = () => (
    <div style={{ textAlign: 'center' }}>
      <img src="https://cdn.boxpressd.io/placeholder/no_humidor.png" style={{ maxWidth: 200, marginBottom: 20, marginTop: 30, opacity: 0.5 }} />
      <div style={{ fontSize: 14 }}>No Virtual Humidors</div>
      {
        isCurrentUser ? (
          <div style={{ marginTop: 20 }}>
            <MaterialButton variant="outlined" onClick={toggleShowHumidorEditor}>Create Humidor</MaterialButton>
          </div>
        ) : null
      }
      { isCurrentUser && renderHumidorEditor() }
    </div>
  );

  const renderHumidorEntries = () => {
    if (loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <HumidorEntryCardSkeleton />
          <HumidorEntryCardSkeleton />
          <HumidorEntryCardSkeleton />
        </div>
      );
    }
    if (!contents || contents.length === 0) {
      let name = 'Your';
      if (!isCurrentUser) {
        // name = `${}'s`; // TODO Grab the user's name? Doesn't make a lot of sense to do a network call for it
        name = 'This';
      }
      return (
        <div style={{ textAlign: 'center' }}>
          <img />
          <div style={{ fontSize: 14, marginTop: 50 }}>{`${name} humidor is empty`}</div>
        </div>
      );
    }

    // FIXME The session and humidor editors should probably be rendered here with some prop to open / set the cigar?
    if (displayType === 'grid') {
      return (
        <Grid container spacing={1}>
          {contents.map((entry) => (
            <Grid key={entry.id} item xs={6}>
              <HumidorEntryCardGrid item={entry} />
            </Grid>
          ))}
        </Grid>
      );
    }
    if (displayType === 'circle') {
      return (
        <Grid container spacing={1}>
          {contents.map((entry) => (
            <Grid key={entry.id} item xs={4}>
              <HumidorEntryCardCircle item={entry} />
            </Grid>
          ))}
        </Grid>
      );
    }
    return contents.map((entry) => (
      <HumidorEntryCard key={entry.id} item={entry} />
    ));
  };

  const renderHumidorSortSheet = () => {
    if (isIOS) {
      return (
        <div>
          <ActionSheet
            show={state.showHumidorSortSheet}
            menus={sortOptions}
            onRequestClose={toggleHumidorSortSheet}
            cancelText="Cancel"
          />
        </div>
      );
    }
    return (
      <div>
        <Drawer
          anchor="bottom"
          open={state.showHumidorSortSheet}
          onClick={toggleHumidorSortSheet}
          onRequestClose={toggleHumidorSortSheet}
        >
          <div
            role="presentation"
            onClick={toggleHumidorSortSheet}
            onKeyDown={toggleHumidorSortSheet}
          >
            <List>
              {/* FIXME Render a cancel button? */}
              { sortOptions.map((item) => (
                <ListItem onClick={item.onClick}>
                  <ListItemText primary={item.content} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  };

  const renderCoverPhotoUploadPicker = () => (
    <input
      id="cover-photo-picker"
      type="file"
      accept="image/*"
      ref={(ref) => addCoverPhoto = ref}
      style={{ display: 'none' }}
      onChange={async (e) => {
        console.log(e.target.files);
        setState({
          selectedCoverImage: await toBase64(e.target.files[0]),
          cropType: 'cover',
          showMediaCropper: true,
        });
      }}
    />
  );

  const renderMediaCropper = () => {
    const { showMediaCropper, selectedProfileImage, selectedCoverImage, cropType } = state;
    return (
      <MediaCropper
        src={cropType === 'cover' ? selectedCoverImage : selectedProfileImage}
        open={showMediaCropper}
        toggle={() => {
          setState({
            showMediaCropper: !showMediaCropper,
          });
        }}
        onClose={() => {
          setState({
            showMediaCropper: false,
          });
        }}
        onSave={(croppedCanvas) => {
          const base64 = croppedCanvas.toDataURL();
          // TODO Update the corresponding image view
          croppedCanvas.toBlob((blob) => {
            console.log('Canvas blob');
            console.log(blob);

            uploadMedia(blob, base64.split(';')[0].split('/')[1]);

            setState({
              showMediaCropper: false,
            });
          }/* , 'image/png' */);
        }}
      />
    );
  };

  const renderLoadingView = () => (
    <div>
      <Skeleton height={175} width="100%" />
      <AppBar
        position="static"
        color="white"
        style={{ height: 56, marginBottom: 10, padding: '3px 20px' }}
      >
        <Skeleton height={40} />
      </AppBar>
      <Skeleton />
      <HumidorEntryCardSkeleton />
    </div>
  );

  const renderHumidorEntryEditor = () => (
    <HumidorEntryEditor
      cigar={selectedCigar}
      open={showHumidorEntryEditor}
      toggle={toggleHumidorEntryEditor}
      onClose={closeHumidorEntryEditor}
    />
  );

  const renderHumidorManager = () => (
    <HumidorManager
      open={state.showHumidorManager}
      toggle={toggleHumidorManager}
      onClose={closeHumidorManager}
      humidors={humidors}
    />
  );

  const renderHumidorEditor = () => (
    <HumidorEditor
      show={state.showHumidorEditor}
      toggle={toggleShowHumidorEditor}
      onClose={closeHumidorEditor}
    />
  );

  const renderMobileModal = () => {
    const rightButtons = [];
    if (!isMobileOnly) {
      rightButtons.push(<MaterialButton
        aria-label="Add Humidor Entry"
        onClick={() => {
          window.dispatchEvent(new CustomEvent('showSearch', {
            detail: {
              action: {
                route: 'profile/humidors',
                params: '?action=add',
                results: 'cigars',
                replace: true,
              },
            },
          }));
        }}
      >
        <Icon name="plus" style={{ height: 20, width: 20 }} />
      </MaterialButton>);
    }
    rightButtons.push(
      <MaterialButton aria-label="Share" onClick={share}>
        {isIOS && (<Icon name="share" style={{ height: 20, width: 20 }} />)}
        {!isIOS && (<Icon name="share2" style={{ height: 20, width: 20 }} />)}
      </MaterialButton>
    );
    return (
      <ModalView
        open
        showFrom="right"
        hideAppBarShadow
        // onClick={props.toggleModal}
        onClose={closeModal}
        path={path}
        parentPath={path.replace('/humidors', '')}
        title={(
          <div>
            <VineMenu
              open={showHumidorsDropDown}
              onClick={toggleHumidorsDropDown}
              title={activeHumidor ? activeHumidor.name : 'Default Humidor...'}
            />
          </div>
        )}
        rightButtons={rightButtons}
      >
        <div style={{ paddingBottom: 60 }}>
          <Helmet>
            <title>Virtual Humidor | Boxpressd</title>
          </Helmet>
          {renderCoverPhotoUploadPicker()}
          {isCurrentUser && (
            <div
              className="edit-humidor-cover-photo-btn-overlay show"
              onClick={() => {
                addCoverPhoto.click();
              }}
            >
              {'Edit Humidor Photo'}
            </div>
          )}
          <div
            className="cover-photo"
            style={{
              backgroundImage: `url(${activeHumidorImage}), url(https://dev-cdn.boxpressd.io/placeholder/venue_bg.jpg)`,
              maxHeight: 175,
            }}
          />
          <MobileSearchBar
            id="humidor-filter"
            inputId="humidor-filter-input"
            ref={(el) => filterSearchBar = el}
            placeholder="Filter cigars in this humidor..."
            forceRerender
            onQueryChange={(query) => {
              console.log(query);
              if (query && query.length) {
                isFiltered = true;
                setContents(sortContents(activeHumidor.contents ? activeHumidor.contents.filter((entry) => {
                  const { cigar } = entry.scan;
                  if (cigar && (cigar.brand || cigar.name || cigar.full_name)) {
                    return filterName(cigar.brand, query) || filterName(cigar.name, query) || filterName(cigar.full_name, query);
                  }
                  return false;
                }) : [], selectedSort));
              } else {
                isFiltered = false;
                setContents(sortContents([...activeHumidor.contents], selectedSort));
              }
            }}
            rightButtons={[
              <MaterialButton
                aria-label="Change display type"
                onClick={toggleDisplayView}
              >
                {displayType === 'grid' && <Icon vendor="ion" name="apps-outline" className="ion" style={{ height: 20, width: 20 }} />}
                {displayType !== 'grid' && <Icon name={displayType === 'circle' ? 'list' : 'grid'} />}
              </MaterialButton>,
              <MaterialButton aria-label="Sort entries" onClick={toggleHumidorSortSheet}><Icon name="sliders" /></MaterialButton>,
            ]}
          />
          <div style={{ paddingBottom: insetBottom }}>
            <div style={{ marginTop: 16, textAlign: 'center' }}>
              {`Total cigars in humidor: ${calculateCigarCount()} (${calculateTotalValue()})`}
            </div>
            { activeHumidor && renderHumidorEntries() }
          </div>
        </div>
        { renderHumidorSortSheet() }
        { isCurrentUser && state.showHumidorManager && renderHumidorManager() }
        <VineMenuDropDown
          humidors={humidors}
          open={showHumidorsDropDown}
          onClick={toggleHumidorsDropDown}
          showManager={isCurrentUser}
          onShowManager={() => {
            console.log('Showing humidor manager...');
            setState({
              showHumidorManager: true,
              showHumidorsDropDown: false,
            });
          }}
          onSelectHumidor={(humidor) => {
            preloadHumidorImage(humidor);
            // firstLoad = true;
            setState({
              showHumidorsDropDown: false,
            });
            preventUpdate = false;
            setActiveHumidor(humidor);
          }}
        />
        { isCurrentUser && renderMediaCropper() }
        { isCurrentUser && (
          <Fab
            color="primary"
            aria-label="add"
            className="fab dark"
            style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 999 }}
            onClick={() => {
              window.dispatchEvent(new CustomEvent('showSearch', {
                detail: {
                  action: {
                    route: 'profile/humidors',
                    params: '?action=add',
                    results: 'cigars',
                    replace: true,
                  },
                },
              }));
              setTimeout(() => {
                jQuery('#mobile-search-input').focus();
              }, 250);
            }}
          >
            <Icon name="plus" style={{ height: 20, width: 20, color: '#121212' }} />
          </Fab>
        )}
      </ModalView>
    );
  };

  const renderDesktopModal = () => (
    <div>
      <Helmet>
        <title>Virtual Humidor | Boxpressd</title>
      </Helmet>
      <Row>
        <Col md={9}>
          <div style={{ float: 'left' }}>
            <FormGroup>
              <Input
                placeholder="Filter cigars in this humidor..."
                style={{ width: 300 }}
                onChange={(e) => {
                  if (activeHumidor.contents) {
                    const query = e.target.value;
                    if (query && query.length) {
                      isFiltered = true;
                      setContents(sortContents(activeHumidor.contents.filter((entry) => {
                        const { cigar } = entry.scan;
                        if (cigar) {
                          return cigar.brand.toLowerCase().startsWith(query.toLowerCase())
                            || cigar.name.toLowerCase().startsWith(query.toLowerCase());
                        }
                        return false;
                      })));
                    } else {
                      isFiltered = false;
                      setContents(sortContents([...activeHumidor.contents], selectedSort));
                    }
                  } else {
                    ModalDialog.show({
                      title: 'Unable to filter',
                      message: 'This humidor is empty. To add cigars to it, use the + button.',
                    });
                  }
                }}
              />
            </FormGroup>
          </div>
          <div style={{ float: 'right' }}>
            <Dropdown
              showTriangle
              className="dib"
              style={{ marginRight: 10 }}
              dropdownOpen={state.showSortOptions}
              toggle={() => {
                setState({
                  showSortOptions: !state.showSortOptions,
                });
              }}
            >
              <Dropdown.Toggle
                tag="button"
                className="btn btn-grey-2"
              >
                <span className="text">Sort</span>
                <span className="icon">
                  <Icon name="chevron-down" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu tag="ul" className="nav">
                {sortOptions.map((option) => (
                  <li>
                    <Link
                      to="#"
                      className="nav-link"
                      onClick={() => {
                        option.onClick();
                        setState({
                          showSortOptions: false,
                        });
                      }}
                    >
                      <span>{option.content}</span>
                    </Link>
                  </li>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <ButtonGroup>
              <Button
                color="grey-2"
                className="btn-uniform"
                onClick={() => {
                  setDisplayType('grid');
                  Transducer.setPreference('humidorDisplayType', 'grid');
                }}
              >
                <span className="icon">
                  <Icon name="grid" />
                </span>
              </Button>
              <Button
                color="grey-2"
                className="btn-uniform"
                onClick={() => {
                  setDisplayType('list');
                  Transducer.setPreference('humidorDisplayType', 'list');
                }}
              >
                <span className="icon">
                  <Icon name="list" />
                </span>
              </Button>
            </ButtonGroup>
            {isCurrentUser && (
              <Button
                color="grey-2"
                className="btn-uniform"
                onClick={() => {
                  window.dispatchEvent(new CustomEvent('showSearch', {
                    detail: {
                      action: {
                        route: 'profile/humidors',
                        params: '?action=add',
                        results: 'cigars',
                        replace: true,
                      },
                    },
                  }));
                }}
                style={{ marginLeft: 10 }}
              >
                <span className="icon">
                  <Icon name="plus" />
                </span>
                {!isMobile && <span className="text">{'Add Cigar'}</span>}
              </Button>
            )}
          </div>
          <div style={{ marginTop: isTablet ? 60 : undefined }}>
            { activeHumidor && renderHumidorEntries() }
          </div>
        </Col>
        <Col md={3} style={{ borderLeft: props.settings.night_mode ? '1px solid #3c424c' : '1px solid #dee2e6' }}>
          <div style={{ position: 'sticky', top: 100 }}>
            <div style={{ height: 30 }}>
              <h5 style={{ float: 'left' }}>All Humidors</h5>
              {isCurrentUser && (
                <span
                  style={{ float: 'right', cursor: 'pointer' }}
                  onClick={() => setState((nextProps) => ({ ...nextProps, showHumidorManager: true }))}
                >
                  {'Manage'}
                </span>
              )}
              {isCurrentUser && (!humidors || humidors.length === 0) && (
                <div style={{ textAlign: 'center' }}>
                  <div className="clearfix" />
                  <div style={{ marginBottom: 12 }}>You don't have any humidors yet</div>
                  <MaterialButton variant="outlined" onClick={toggleShowHumidorEditor}>Create Humidor</MaterialButton>
                </div>
              )}
            </div>
            <List>
              { humidors && humidors.map((humidor) => {
                const isActive = activeHumidor && (activeHumidor.id === humidor.id);
                return (
                  <ListItem
                    className={isActive ? 'humidor-list-item active' : 'humidor-list-item'}
                    style={{ cursor: 'pointer', borderRadius: 5, flexDirection: isMobile ? 'column' : 'row' }}
                    alignItems="flex-start"
                    onClick={() => {
                      preventUpdate = false;
                      setActiveHumidor(humidor);
                    }}
                  >
                    {/* TODO Hover avatar to show up-right arrow or expand icon to show the image fullscreen on click */}
                    <ListItemAvatar style={{ margin: 'auto' }}>
                      <Avatar alt={humidor.name} src={humidor.image_url}>{humidor.name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={humidor.name}
                      secondary={(
                        <>
                          <>
                            {humidor.contents.length}
                            {' unique cigars, '}
                            {humidorTotalEntryCount(humidor)}
                            {' total'}
                          </>
                          <br />
                          <>
                            {humidorTotalValue(humidor)}
                            {' total value'}
                          </>
                        </>
                      )}
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </Col>
      </Row>
      { isCurrentUser && renderHumidorManager() }
      { isCurrentUser && renderMediaCropper() }
      { isCurrentUser && renderHumidorEditor() }
    </div>
  );

  const filterName = (name, query) => {
    if (!name) {
      return false;
    }
    return name.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase().indexOf(query.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase()) !== -1;
  };

  const { showHumidorsDropDown } = state;
  const insetBottom = safeAreaInsets.bottom;
  const path = isCurrentUser ? '/profile/humidors' : `/users/${hashids.encode(currentUserId)}/humidors`;
  return (
    <>
      { loading && renderLoadingView() }
      { isMobileOnly && !loading && humidors.length === 0 && renderNoHumidor() }
      { isCurrentUser && showHumidorEntryEditor && renderHumidorEntryEditor() }
      { isMobileOnly && !loading && humidors.length > 0 && renderMobileModal() }
      { !isMobileOnly && renderDesktopModal() }
    </>
  );
}

HumidorComponent.propTypes = {
  // user: PropTypes.object,
  loading: PropTypes.bool,
  humidors: PropTypes.array,
  // activeHumidor: PropTypes.object,
  // contents: PropTypes.array,
};

HumidorComponent.defaultProps = {
  // user: null,
  loading: true,
  humidors: null,
  // activeHumidor: null,
  // contents: null,
};

function mapStateToProps(state, props) {
  console.log('Humidor mapStateToProps');
  console.log(state.toJS());
  console.log(props);

  let currentUserId;
  if (window.location.pathname === '/profile/humidors') {
    currentUserId = state.get('auth').toJS().user.id;
  } else {
    const parts = window.location.pathname.split('/');
    console.log('Humidor parts:');
    console.log(parts);
    const hashId = parts[2];
    console.log(parts[2]);
    currentUserId = parseInt(hashids.decode(hashId)[0]);
    console.log(`Reselecting humidors for ${currentUserId}`);
  }

  return {
    auth: state.get('auth') ? state.get('auth').toJS() : {},
    settings: state.get('settings'),
    loading: selectHumidorLoading(currentUserId)(state),
    // humidors: selectAllHumidors(currentUserId)(state),
    // contents: selectHumidorContents(props.activeHumidor && props.activeHumidor.id)(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    requestHumidors: (userId, callback) => dispatch(requestHumidorsAction(userId, callback)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HumidorComponent));
